.colorBox
  width 200px
  margin 10px 0

.colorLine
  display inline-flex
  flex-direction: column

.square
  display: inline-block
  vertical-align: middle
  width 17px
  height 17px
  cursor pointer
  box-sizing content-box
  border-width: 2px

.isNotSelectable
  cursor initial

.theSelectedColor
  margin 10px 0
  display: flex
  align-items: center
  color:#596262
  font-weight 500
  font-size 12px

  .square
    border-radius 3px

  .noSelection
    color:#B1B8B8


.isUsed
  background-size: 17px 17px
  background-image: url('../../media/icons/checkWhite.svg')
