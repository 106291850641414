.avatarContainer
  display flex
  align-items center
  
  border-radius 50%
  overflow hidden

.avatarIcon
  flex auto 0 0
  height: inherit
  display: flex

  img
    height: inherit
    width: auto !important
  