@import "~commonStyles/variables.styl"

.outer
  display: flex
  flex-direction: column
  height: auto
  background-color: color-background
  min-height: 100%
  border-radius: 6px 6px 0px 0px;

.headerWrapper
  position: sticky
  top: 0
  left: 0
  width: 100%
  z-index: z-index-header-wrapper

.inner
  position: relative
  display: flex
  flex-grow: 1

.content
  max-width 100%
  position: relative
  flex-grow: 1
  background-color: color-content

.sidebarLayout
  position: relative
  display: flex
  flex-direction: row
  height: 100%

.mainPanel
  flex-grow: 1

.topBanner
  padding 10px;
  background-color: #d9eef3;
  color: #007387;
  display flex
  flex-direction row
  justify-content center
  align-items center
  line-height 16px
  gap 8px

  span
    margin-right 4px;
    a
      text-decoration: underline;
      color black
  svg
    cursor pointer
    &:hover
      color: black


@media (max-width: mobile-max-width)
  .headerWrapper
    position: relative