.rounded-corners {
  border-radius: 3px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.bordered {
  border: 1px solid #ccc;
}
.outer {
  max-width: 700px;
  min-width: ;
  margin: 50px auto 0;
  background-color: #fff;
  border-radius: 10px;
  -webkit-box-shadow: 0px 2px 4px #afafaf;
  -ms-box-shadow: 0px 2px 4px #afafaf;
  -o-box-shadow: 0px 2px 4px #afafaf;
  box-shadow: 0px 2px 4px #afafaf;
}
.inner {
  min-width: ;
  margin: 0 auto;
  padding: 50px 30px 70px;
  color: #666;
}
@media (max-width: 750px) {
  .inner {
    padding: 50px 10px 70px;
  }
}
.inner h1 {
  margin: 10px 0;
  text-align: center;
  color: #4a90e2;
  font-size: 2em;
  font-weight: normal;
}
.inner h3 {
  margin: 10px 0;
  text-align: center;
  color: #666;
  font-size: 1.2em;
  font-weight: normal;
}
.alt {
  margin-top: 25px;
}
