.appContainer {
  height: calc(100% - 16px);
  border-radius: 6px 6px 0px 0px;
  border: 0.5px solid #c5cbcb;
  margin: 8px;
}
html > body {
  height: 99vh;
  background: #f2f6f6;
  overflow-y: auto;
}
