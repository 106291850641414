.outer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  height: auto;
  background-color: #fff;
  min-height: 100%;
  border-radius: 6px 6px 0px 0px;
}
.headerWrapper {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 3;
}
.inner {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}
.content {
  max-width: 100%;
  position: relative;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  background-color: #fafafa;
}
.sidebarLayout {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  height: 100%;
}
.mainPanel {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}
.topBanner {
  padding: 10px;
  background-color: #d9eef3;
  color: #007387;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  line-height: 16px;
  gap: 8px;
}
.topBanner span {
  margin-right: 4px;
}
.topBanner span a {
  text-decoration: underline;
  color: #000;
}
.topBanner svg {
  cursor: pointer;
}
.topBanner svg:hover {
  color: #000;
}
@media (max-width: 750px) {
  .headerWrapper {
    position: relative;
  }
}
