:global body .btn {
  white-space: normal;
  word-wrap: break-word;
  margin: 3px 0;
}
:global body .btn.btn-custom-type-hint {
  padding: 0 0.2rem;
  font-size: 0.7rem;
  border: none;
  margin: 0;
}
:global body .btn.btn-secondary {
  color: #292b2c;
  background-color: #fff;
  border-color: #ccc;
}
:global body .btn.btn-v2design,
:global body .btn.btn-danger {
  width: 100%;
  line-height: 24px;
  font-weight: 500;
  height: 35px;
  padding: 0px 8px;
  color: #596262;
  border-radius: 6px;
  background: #e8eded;
  font-size: 12px;
  font-style: normal;
  letter-spacing: -0.12px;
  font-family: Inter;
  border: 0;
  outline: 0;
  cursor: pointer;
  min-height: -webkit-fit-content;
  min-height: -moz-fit-content;
  min-height: fit-content;
}
:global body .btn.btn-danger {
  color: #fff;
  background-color: #dc3545;
}
:global body .btn.btn-v2design:hover,
:global body .btn.btn-danger:hover {
  text-decoration: none;
  color: var(--highlight-color) !important;
}
