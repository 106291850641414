// override for original bootstrap styles
:global body .btn
  white-space normal
  word-wrap break-word
  // TODO: remove explicit margin here - in need of margin, use higher-level layout constructs like FormGroup
  margin 3px 0

  // styles for custom button types
  &.btn-custom-type-hint
    padding 0 0.2rem
    font-size 0.7rem
    border none
    // this one is needed only because of the temporary style for .btn
    margin 0

  &.btn-secondary
    color: #292b2c
    background-color: #fff
    border-color: #ccc

  &.btn-v2design,&.btn-danger
    width: 100%;
    line-height: 24px;
    font-weight: 500;
    height: 35px;
    padding: 0px 8px;
    color: #596262;
    border-radius: 6px;
    background: #e8eded;
    font-size: 12px;
    font-style: normal;
    letter-spacing: -0.12px;
    font-family: Inter;
    border: 0;
    outline: 0;
    cursor pointer;
    min-height: fit-content;

  &.btn-danger
    color: #fff;
    background-color: #dc3545;

  &.btn-v2design:hover,&.btn-danger:hover
    text-decoration: none;
    color: var(--highlight-color) !important;


