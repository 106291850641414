.header,
.desktopHeader {
  --highlight-color: #2d2d2d;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  position: relative;
}
.desktopHeader {
  height: 50px;
  border-bottom: 0.5px solid #d0d6d6;
  background: #f6fafa;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0 15px;
  line-height: 1;
}
.row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.boardPath {
  min-width: 0;
  padding: 0 15px;
  -webkit-box-flex: 0;
      -ms-flex: 0;
          flex: 0;
  -ms-flex-preferred-size: 373px;
      flex-basis: 373px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: #849090;
  font-family: Inter;
  font-style: bold;
  font-weight: 500;
  line-height: 18px; /* 128.571% */
  letter-spacing: -0.28px;
  font-size: 14px;
}
.boardPath .boardLink:last-child {
  color: inherit;
}
.panelButton {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  color: #596262;
  border-radius: 6px;
  height: 30px;
  width: 30px;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background: #e8eded;
}
.boardPath:hover .expandedBoardPath {
  display: block;
}
.boardPath:hover .shortBoardPath {
  display: block;
}
.expandedBoardPath {
  background-color: #fff;
  border: 1px solid #ccc;
  display: none;
  padding: 10px;
  padding-left: 8px;
  margin-left: -9px;
  margin-top: -1px;
}
.shortBoardPath {
  padding: 10px;
  padding-left: 0px;
  white-space: nowrap;
}
.boardLink {
  color: #aaa;
}
.boardLink:hover {
  text-decoration: underline !important;
}
.boardLinkMinimal {
  color: #fff;
}
.boardPathIcon {
  margin: 0px 10px;
  color: #aaa;
}
.rootLink {
  padding: 10px 0;
}
@media (max-width: 750px) {
  .rootLink {
    padding-left: 15px;
  }
}
.expander {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 0px;
          flex: 1 1 0;
}
.searchBar {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  width: 650px;
  max-width: 650px;
  height: 54px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  max-height: 22px;
}
.navLinkWrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
      -ms-flex: none;
          flex: none;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative;
}
.dropdown {
  position: absolute;
  z-index: 3;
  top: 100%;
  -webkit-box-shadow: 0 1px 1px #cbcbcb;
          box-shadow: 0 1px 1px #cbcbcb;
}
.inlineIcon {
  margin: 0px 10px 0px 15px;
  font-size: 16px !important;
}
.dropdownIcon:hover,
.dropdownIcon.selected {
  color: var(--highlight-color) !important;
}
.dropdownIcon.menuIcon {
  margin-left: 10px;
}
.navLink {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  cursor: pointer;
  line-height: 24px;
  height: 22px;
  gap: 8px;
  padding: 0px 8px;
  color: #596262;
  font-family: Inter;
  font-weight: 400;
  font-size: 15px;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.12px;
}
.navLink:hover,
.navLink.selected {
  text-decoration: none;
  color: var(--highlight-color) !important;
}
.viewTypeWrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  white-space: nowrap;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  gap: 10px;
  padding: 0 10px;
}
.viewTypeTab {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 30px;
  width: 90px;
  padding: 0px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: 6px;
  background: #e8eded;
  gap: 8px;
}
.viewTypeTab:hover {
  color: var(--highlight-color) !important;
}
.viewTypeTab.current {
  color: #007387;
  background: #d9eef3;
  font-weight: 500;
}
.menuButtonWrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}
.menuButton {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-left: 8px;
  line-height: 24px;
  color: #596262;
  height: 30px;
  width: 30px;
  background: #e8eded;
  border-radius: 6px;
}
.menu {
  width: 150px;
  right: 0;
  border-radius: 6px;
  background: #e8eded;
}
.loginButton {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: 30px;
  width: 30px;
}
