@import "./normalize.css"
@import '../media/fonts/fonts.css'
@import "~commonStyles/variables.styl"

a
  cursor: pointer
  color color-blue

button
  cursor: pointer

body, html
  height: 100%
  margin: 0
  padding: 0
  font-family: 'Inter', serif
  font-weight 500

input
  font-family: 'Inter', serif
  font-weight 500
