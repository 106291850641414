@import "~commonStyles/mixins.styl"

.input
  border 1px solid #ddd
  font-size 18px
  width 100%
  box-sizing: border-box
  border-radius 5px
  transition border-color 100ms ease-in-out
  outline none
  color #4b4b4b
  font-family: 'Inter', serif;
  font-weight 400

.isLarge
  height 70px
  padding 0 20px

.outlineNone
  border-color transparent

.isSmall
  height 40px
  padding 0 6px

.input:hover, .isSmall:hover
  border-color #bbb

.input:active, .isSmall:active,
.input:focus, .isSmall:focus
  border-color #4A90E2

.copyToClipboard 
  margin-left: 15px
  font-size: 18px
