.appContainer
  height calc(100% - 16px)
  border-radius: 6px 6px 0px 0px;
  border: 0.5px solid #C5CBCB;
  margin: 8px

html > body
  height: 99vh
  background #F2F6F6;
  overflow-y auto
