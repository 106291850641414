@import "variables.styl"
@import "mixins.styl"

.rounded-corners
  border-radius(3px)
  box-sizing: border-box;

.bordered
  border 1px solid #ccc

