@import '~commonStyles/common.styl'
@import '~commonStyles/variables.styl'

controls-container-padding = 10px

._fullDimensionFlexContainer
  display flex
  align-items stretch

.fullHeightFlexRow
  @extend ._fullDimensionFlexContainer
  flex-direction row
  position: relative
  height 100%

.fullWidthFlexColumn
  @extend ._fullDimensionFlexContainer
  flex-direction column
  width 100%

.expansiveFlexItem
  flex-grow 1

// use position: relative to just indicate positioning anchor for child elements,
// but don't change own element's position
.positioningAnchor
  position relative

.fullSizeOverlay
  pointer-events none
  position absolute
  left 0
  top 0
  width 100%
  height 100%

.paddedControlsContainer
  padding-top 0;

.centralCanvasPartUpperNeighbour
  //add distance between this element and main canvas part
  margin-bottom controls-container-padding

.buttonsContainer
  // display buttons at the bottom
  display flex
  flex-direction column
  justify-content flex-end

.notReadyToRenderWarning
  @extend .fullHeightFlexRow
  justify-content: space-around

.modal
  z-index: z-index-graph-view-modal
  line-height: 1.2em
  position: fixed
  padding: 20px
  bottom: 20px
  width: 250px
  left: 70px
  background-color: #ff9800
  color: #fff
  border-radius: 5px
  opacity: 0.6
  transition: opacity 0.6s
  display:flex
  justify-content: space-between
  opacity: 0.8

  &:hover
    opacity: 1

.modalCloseButton
  margin-left:15px
  color: white
  font-weight: bold
  font-size: 22px
  cursor: pointer
  transition: 0.3s

  &:hover
    color: black

.mainSearchBar
  height: 40px
  max-width: main-search-bar-max-width
  padding-left: 14px
  border-radius: 6px
  font-weight: 400
  color: #666

.searchBarContainer
  display: flex
  padding: 10px;

  .searchBar
    pointer-events: auto
    flex: 1
    margin-left: 10px
    display: flex

.filterBarContainer
  margin-top: 10px
  display: flex

  .filterBar
    pointer-events: auto
    flex: 1
    margin-left: 10px

.searchOrFilterBarButton
  pointer-events: auto
  width: 40px
  height: 40px
  padding: 0
  background-color: white
  border: 1px solid rgba(0, 0, 0, 0.3)
  border-radius: 6px
  outline none
  color #596262
  &:hover
    color #000000
  &:active, &:focus
    outline none


.searchOrFilterBarButtonIcon
  color: black
  line-height: 19px
  font-size: 19px

.logo
  width: 48px
  height: 48px
  animation: logoFade 2s ease-in-out infinite
  opacity: 0.3

  @keyframes logoFade
    0%
      opacity: 0.3
    50%
      opacity: 1
    100%
      opacity: 0.3

.logoContainer
  display: flex
  align-items: center
  justify-content: center
  flex-direction: column
  gap: 10px
  color: rgb(132, 144, 144)
  
