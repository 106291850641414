.avatarContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: 50%;
  overflow: hidden;
}
.avatarIcon {
  -webkit-box-flex: 1;
      -ms-flex: auto 0 0px;
          flex: auto 0 0;
  height: inherit;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.avatarIcon img {
  height: inherit;
  width: auto !important;
}
