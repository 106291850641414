.rounded-corners {
  border-radius: 3px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.bordered {
  border: 1px solid #ccc;
}
._fullDimensionFlexContainer,
.fullHeightFlexRow,
.fullWidthFlexColumn,
.notReadyToRenderWarning {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
}
.fullHeightFlexRow,
.notReadyToRenderWarning {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  position: relative;
  height: 100%;
}
.fullWidthFlexColumn {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 100%;
}
.expansiveFlexItem {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}
.positioningAnchor {
  position: relative;
}
.fullSizeOverlay {
  pointer-events: none;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.paddedControlsContainer {
  padding-top: 0;
}
.centralCanvasPartUpperNeighbour {
  margin-bottom: 10px;
}
.buttonsContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}
.notReadyToRenderWarning {
  -ms-flex-pack: distribute;
      justify-content: space-around;
}
.modal {
  z-index: 4;
  line-height: 1.2em;
  position: fixed;
  padding: 20px;
  bottom: 20px;
  width: 250px;
  left: 70px;
  background-color: #ff9800;
  color: #fff;
  border-radius: 5px;
  opacity: 0.6;
  -webkit-transition: opacity 0.6s;
  transition: opacity 0.6s;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  opacity: 0.8;
}
.modal:hover {
  opacity: 1;
}
.modalCloseButton {
  margin-left: 15px;
  color: #fff;
  font-weight: bold;
  font-size: 22px;
  cursor: pointer;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.modalCloseButton:hover {
  color: #000;
}
.mainSearchBar {
  height: 40px;
  max-width: 661px;
  padding-left: 14px;
  border-radius: 6px;
  font-weight: 400;
  color: #666;
}
.searchBarContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 10px;
}
.searchBarContainer .searchBar {
  pointer-events: auto;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  margin-left: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.filterBarContainer {
  margin-top: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.filterBarContainer .filterBar {
  pointer-events: auto;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  margin-left: 10px;
}
.searchOrFilterBarButton {
  pointer-events: auto;
  width: 40px;
  height: 40px;
  padding: 0;
  background-color: #fff;
  border: 1px solid rgba(0,0,0,0.3);
  border-radius: 6px;
  outline: none;
  color: #596262;
}
.searchOrFilterBarButton:hover {
  color: #000;
}
.searchOrFilterBarButton:active,
.searchOrFilterBarButton:focus {
  outline: none;
}
.searchOrFilterBarButtonIcon {
  color: #000;
  line-height: 19px;
  font-size: 19px;
}
.logo {
  width: 48px;
  height: 48px;
  -webkit-animation: logoFade 2s ease-in-out infinite;
          animation: logoFade 2s ease-in-out infinite;
  opacity: 0.3;
}
@-webkit-keyframes logoFade {
  0% {
    opacity: 0.3;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.3;
  }
}
@keyframes logoFade {
  0% {
    opacity: 0.3;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.3;
  }
}
.logoContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 10px;
  color: #849090;
}
