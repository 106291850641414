@import "~commonStyles/textfield.styl"
@import "~commonStyles/variables.styl"

.container
  // the search bar can be displayed in an overlay with disabled pointer-events,
  // so need to ensure normal events inside the search bar
  pointer-events auto
  width 100%

.loadingIcon
  width: 1em
  height: 1em
  align-self: center

.searchInputContainer
  @extends .textfield
  display flex
  width 100%
  justify-content left
  align-items center
  font-size 16px
  line-height 30px
  background transparent
  text-align left
  text-shadow none
  position relative

  &.bareContainer
    font-size: inherit
    line-height: inherit
    min-height: auto

  &:focus-within
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.1)
    border-color: #aaa


.regular
  .searchInputContainer
    background #fff

.unstyled
  .searchInputContainer
    background inherit
    color inherit
    border none

.searchInput
  box-sizing: border-box
  flex 1 1
  height: 32px
  line-height: 30px
  width 100%
  border none
  outline none
  background transparent
  word-wrap: unset
  resize: none
  white-space: nowrap

  &.multiline
    line-height: inherit
    white-space: pre-wrap
    max-height: 300px

    // :placeholder-shown for UniversalTextInput
    &:empty
      line-height: 32px
      min-height: 32px
      overflow: hidden

  &.bareInput
    line-height: inherit
    height: inherit

.searchBarXButton
  flex 0 0
  line-height 30px
  width 42px
  text-align center
  font-size 24px
  color #aaa
  font-family: "Inter", serif;
  background transparent
  opacity 0.6
  cursor pointer
  position relative

.autocompleteWrapper
  font-size 16px
  line-height 30px
  cursor pointer
  box-shadow(0 2px 4px rgba(0,0,0,0.2))
  border 1px solid color-lighter-gray
  border-top none
  background-color #fff
  max-width: main-search-bar-max-width

  .controls
    border-top 1px solid color-lightest-gray

  .addRelatedButton
    background-color: button-background-color
    border: none
    font-size: 12px
    line-height: inherit
    margin: 5px 15px 5px
    padding: 2px 20px
    border-radius 6px;

    &:hover
      background-color: color-lighter-gray

    &:active, &:focus
      background-color: #bbb


.searchResult
  box-sizing: border-box
  height 30px
  border none
  padding-left 14px
  padding-right 14px
  overflow hidden
  white-space nowrap
  text-overflow ellipsis
  font-family sans-serif
  border-top 1px solid #eee

  background-color: white
  color: #666

.selectedSearchResult
  background-color: #89c7e4
  color: white
  color: #666

.header
  background-color: color-light-gray
  color: white

.match
  background-color #646464
  color white

.recentSearchesHeader {
  padding-left 14px
  padding-right 14px
  font-size: 11px
  text-transform: uppercase
  background-color: #eee
  color: #999
  border-bottom: 1px solid color-lightest-gray
}

.boardSuggestionWrapper {
  display flex
  justify-content space-between
  align-items center
  flex-direction row
  flex-wrap nowrap
}

.boardBadge {
  display: inline-block;
  padding: 0.25rem 0.5rem;
  font-size: 0.60rem;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  align-items: center;
  border-radius: 0.375rem;
  background-color: #E0E5E5;
  font-weight: 500;
  color: #596262;
  margin-left: 2px;
}
