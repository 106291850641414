
@import "~commonStyles/variables.styl"

icon-size = 30px

.header
  //background-color header-background-color
  --highlight-color: #2d2d2d
  border-top-left-radius: 6px;
  border-top-right-radius 6px;
  position relative

.desktopHeader
  @extends .header
  height 50px
  border-bottom: 0.5px solid #D0D6D6;
  background: #f6fafa
  display flex
  flex-direction row
  justify-content flex-start
  align-items center
  padding 0 15px
  line-height 1

.row
  display flex
  flex-direction row
  align-items center

.boardPath
  min-width 0
  padding 0 15px
  flex 0
  flex-basis 373px
  display flex
  align-items center
  color: #849090;
  font-family: Inter;
  font-style: bold;
  font-weight: 500;
  line-height: 18px; /* 128.571% */
  letter-spacing: -0.28px;
  font-size: 14px;
  

  .boardLink:last-child
    color inherit

.panelButton
  display flex
  flex-direction row
  color #596262
  border-radius 6px
  height icon-size
  width icon-size
  display flex
  justify-content center
  align-items center
  background #e8eded

.boardPath:hover
  .expandedBoardPath
    display block

  .shortBoardPath
    display block

.expandedBoardPath
  background-color white
  border 1px solid color-lighter-gray
  display none
  padding 10px
  padding-left 8px
  margin-left -9px // compensate for border+padding
  margin-top -1px // compensate for border

.shortBoardPath
  padding 10px
  padding-left 0px
  white-space: nowrap

.boardLink
  color #aaa
  &:hover
    text-decoration underline !important

.boardLinkMinimal
  color: white

.boardPathIcon
  margin 0px 10px
  color #aaa

.rootLink
  padding: 10px 0
  @media(max-width: mobile-max-width)
    padding-left: 15px

.expander
  flex 1 1 0

.searchBar
  flex 1 1 auto
  width 650px
  max-width 650px
  height header-height
  display flex
  align-items center
  max-height: 22px

.navLinkWrapper
  display flex
  flex none
  align-items center
  position relative

.dropdown
  position absolute
  z-index z-index-dropdown
  top 100%
  box-shadow 0 1px 1px #cbcbcb

.inlineIcon
  margin 0px 10px 0px 15px
  font-size 16px !important

dropdownWidth = 26px
.dropdownIcon
  &:hover, &.selected
    color var(--highlight-color) !important

.dropdownIcon.menuIcon
  margin-left: 10px

.navLink
  display: flex
  align-items: center
  justify-content center
  cursor pointer
  line-height 24px
  height: 22px;
  gap: 8px;
  padding: 0px 8px;
  color: #596262;
  font-family: Inter;
  font-weight: 400
  font-size: 15px;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.12px;
    
  &:hover, &.selected
    text-decoration none
    color var(--highlight-color) !important

.viewTypeWrapper
  display flex
  flex-direction row
  white-space nowrap
  flex 1
  gap: 10px;
  padding 0 10px
  

.viewTypeTab
  display: flex;
  height: icon-size
  width: 90px;
  padding: 0px;
  align-items: center;
  border-radius: 6px;
  background: button-background-color;
  gap: 8px;

  &:hover
    color var(--highlight-color) !important

.viewTypeTab.current
  color #007387
  background #d9eef3
  font-weight: 500

.menuButtonWrapper
  display flex
  align-items center
  justify-content flex-end

.menuButton
  display flex
  align-items center
  justify-content center
  margin-left 8px
  line-height 24px
  color: #596262;
  height icon-size
  width icon-size
  background: button-background-color;
  border-radius 6px

.menu
  width 150px
  right 0
  border-radius 6px
  background: button-background-color;

.loginButton
  display flex
  align-items center
  justify-content center
  height icon-size
  width icon-size

  